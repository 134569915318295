<template>
  <page :title="i18n.title">
    <div class="order-warp">
      <order-item
        v-for="item in borrowItems"
        :key="item.pOrderid"
        :item="item"
        @click="clickEmit"
      ></order-item>
      <order-item
        v-for="item in finishItems"
        :key="item.pOrderid"
        :item="item"
        @click="clickEmit"
      ></order-item>
    </div>
    <!-- <popup-warp v-model="isInvoicePopup">
      <div>dddd</div>
    </popup-warp> -->

    <van-dialog
      v-model="isInvoicePopup"
      show-cancel-button
      :confirmButtonText="$t('confirm.msg_fail_confirm')"
      :cancelButtonText="$t('confirm.msg_fail_cancel')"
      :before-close="invoiceReceipt"
    >
      <div class="invoice-popup">
        <van-field
          v-model="invoiceInfo.vatCode"
          :placeholder="i18n.enter_vat_code"
        />
        <van-field
          v-model="invoiceInfo.email"
          :placeholder="i18n.enter_order_email"
        />
        <van-field
          v-model="invoiceInfo.userName"
          :placeholder="i18n.enter_recipient_name"
        />
      </div>
    </van-dialog>
  </page>
</template>

<script>
import { ORDER_LIST, SENDINVOICERECEIPT } from "../../apis/user"
import OrderItem from "../../components/order/item"
export default {
  components: {
    OrderItem
  },
  computed: {
    i18n() {
      return this.$t("order")
    },
    borrowItems() {
      return this.items.filter((item) => {
        return item.pJiesuanid === 0 && (item.pZujie === 1 || item.pZujie === 4)
      })
    },
    finishItems() {
      return this.items.filter((item) => {
        return (item.pZujie !== 1 && item.pZujie !== 4) || item.pJiesuanid === 1
      })
    }
  },
  data() {
    return {
      items: [],
      error: "",
      invoiceInfo: {
        vatCode: "",
        email: "",
        userName: "",
        orderId: ""
      },
      isInvoicePopup: false
    }
  },
  methods: {
    clickEmit(item) {
      this.isInvoicePopup = true
      this.invoiceInfo = {
        vatCode: "",
        email: "",
        userName: "",
        orderId: item.porderid
      }
    },
    // 发票领取
    invoiceReceipt(action, done) {
      if (action === "cancel") {
        return done()
      }
      const invoiceInfo = { ...this.invoiceInfo }
      if (!invoiceInfo.vatCode) {
        this.$toast(this.i18n.enter_vat_code)
        return done(false)
      }
      if (!invoiceInfo.email) {
        this.$toast(this.i18n.enter_order_email)
        return done(false)
      }
      this.$loading(true)
      // 请求发票领取成功回调
      const successCall = (resp) => {
        this.$loading(false)
        if (resp["code"] === 0) {
          this.$toast(this.$t("msg.success"))
          return done()
        } else {
          this.$toast(resp["msg"])
        }
      }
      this.$post(`${SENDINVOICERECEIPT}`, invoiceInfo, successCall, (error) => {
        done(false)
        this.$loading(false)
        this.$toast(error)
      })
    },
    // 加载列表
    loadList(page = 1) {
      this.$loading(true)
      this.$get(
        ORDER_LIST,
        {
          page,
          pageSize: 10
        },
        (resp) => {
          this.$loading(false)
          if (resp.page) {
            this.error = ""
            this.items = resp.page.records
          } else {
            this.error = this.i18n.msg_error
          }
        },
        (error) => {
          this.$loading(false)
          this.error = error
        }
      )
    }
  },
  created() {
    this.loadList()
  }
}
</script>

<style lang="less">
.order-warp {
  padding: 48px;
}
.invoice-popup {
  margin: 64px 0 32px;
  padding: 24px 46px;
}
</style>
