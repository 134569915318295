export default {
  "home": {
    "minute": "minuto",
    "price_info": "Grátis durante os primeiros {mianfei} mins. Cobrança máxima diária {currencyName}{fengding}. O depósito será automáticamente debitado quando a renda alcançar  {currencyName}{yajin}",
    "feature_1": "Adaptado para todos os tipos de telemóveis",
    "feature_2": "Pode ser devolvido em qualquer outra localização",
    "feature_3": "Especialmente concebido- carregamento wireless (apenas em módulos suportados)",
    "tips_1": "O seu {currencyName}{yajin} depósito será reembolsado após tenha devolvido a power bank.",
    "tips_2": "Depósito de recarga {currencyName}{yajin} pode ser alugado, valor utilizado pode ser devolvido",
    "tips_3": "O saldo atual é de {currencyName}{myyue}, pode ser alugado diretamente",
    "submit": "Iniciar aluguer",
    "borrow": "Alugar",
    "msg_fail_title": "Erro",
    "msg_fail_content": "Aluguer falhou, por favor tente novamente",
    "msg_rentcount": "Atualmente tem  {zujieNum} de pedidos por fechar",
    "goborrow": "Vamos carregar!",
    "nearby": "Ver estações próximas",
    "preauth": "Pre-autorização",
    "scan": "SCAN QR"
  },
  "login": {
    "code_title": "Verificação SMS",
    "phone_number": "Número de telemóvel",
    "verification_code": "Por favor introduza o código de verificação",
    "captcha_code": "Código",
    "send_code": "Enviar Código",
    "login": "Iniciar sessão",
    "agree": "Ao iniciar sessão, concorda com",
    "readme": "Termos e Condições da iCHARGE",
    "msg_fail_login": "inicio de sessão falhou",
    "msg_success_login": "Inicio de sessão bem sucedido",
    "msg_fail_user": "Erro a obter a informação do usuário. Quer tentar de novo?",
    "sign_in_with": "Iniciar sessão com",
    "no_sms": "Não recebeu sms",
    "retry": "Reenviar"
  },
  "confirm": {
    "title": "Confirmação de pagamento",
    "paynow": "Pagamento imediato",
    "SMSConfirm": "Por favor pague deacordo com a mensagem recebida no seu telemóvel e clique no botão de confirmar para avançar.",
    "pay_label": "Valor",
    "auth_label": "Pré-autorização",
    "price_tips": "Caso solicite, o seu saldo pode ser retirado. Pode demorar 1-5 dias úteis até aparecer na sua conta.",
    "auth_tips": "A pré-autorização da quantidade de {0}{1} é obrigatória. O valor de renda atual ocorrido será debitado quando a power bank for devolvida.",
    "pay_type": "Métodos de Pagamento",
    "submit": "Confirmar pagamento",
    "msg_loading": "Loading...",
    "msg_fail_title": "Aviso",
    "msg_fail_payment": "Pagamento do depósito inicial falhou",
    "msg_fail_order": "Criar pedido de aluguer falhou",
    "msg_fail_support": "Sistema não suportado",
    "msg_fail_confirm": "Confirmar",
    "msg_fail_retry": "Tentar novamente",
    "msg_fail_cancel": "Cancelar",
    "noPhone": "Por favor insira o número de telemóvel"
  },
  "mine": {
    "title": "Perfil de utilizador",
    "wallet": "Carteira",
    "balance": "Saldo",
    "deposit": "Depósito",
    "wallet_in": "Carregar",
    "wallet_out": "Retirar",
    "wallet_log": "Histórico da Carteira",
    "order": "Pedido",
    "coupon": "Cupão",
    "login_tips": "Por favor inicie sessão",
    "msg_fail_title": "Erro",
    "msg_fail_update": "Modificação falhou, por favor volte a tentar",
    "msg_loading": "Loading...",
    "msg_fail_user": "Erro a obter as informações do utilizador. Deseja tentar de novo?",
    "help": "Ajuda",
    "setting": "Configurações",
    "logout": "Sair da sessão",
    "buy": "Comprar equipmento",
    "menutip1": "iCHARGE na sua localização",
    "menutip2": "Torne-se nosso parceiro e tenha uma estação iCHARGE na sua localização"
  },
  "msg": {
    "no_phone": "Número de telemóvel é obrigatório",
    "loading": "Loading...",
    "title": "Dica",
    "success": "Operação bem sucedida",
    "error": "Erro desconhecido, por favor volte a tentar.",
    "roleerror": "Permissões insuficientes, por favor consulte com o administrador",
    "neterror": "Conecção de internet falhou. Por favor tente mais tarde.",
    "confirm": "Confirmar",
    "download": "Baixar",
    "retry": "Tentar de novo",
    "cancel": "Cancelar",
    "title_fail": "Aviso",
    "vieworder": "Ver pedido",
    "success_payment": "Pagamento completo",
    "success_buy": "Compra completa",
    "fail_payment": "Pagamento falhou",
    "keyword": "Palavra-chave",
    "ispayorder": "Confirmar se o pagamento foi realizado?",
    "unpay": "Por pagar",
    "yespay": "Pago",
    "notopen": "Ainda não aberto",
    "appoffline": "A aplicação ainda não foi lançada, por favor aguarde"
  },
  "result": {
    "title": "Aluger confirmado",
    "tips": "A sua Powerbank está pronta, pegue e Aproveite o seu dia, sem stress!",
    "gotit": "Obtido",
    "price_plan": "Cobrança Standard",
    "msg_success": "Alugado com Sucesso",
    "msg_tips_loading": "A bateria portátil está a sair, por favor aguarde pacientemente...",
    "msg_result_loading": "Pagamento está a ser confirmado...",
    "msg_fail_confirm": "Confirmar",
    "btn_usage": "Nota de Uso",
    "btn_return": "Nota de devolução",
    "deposit": "Depósito",
    "msg_fail_content": "Pedido falhou, por favor aguarde",
    "price_label": "por hora",
    "price_info": "Grátis durante os primeiros {mianfei} mins，Uma cobrança diária máxima de {ccurrency}{fengding}，Depósito será automaticamente deduzido assim que a renda chegue {ccurrency}{yajin}",
    "tips_1": "Por favor retire a power bank <span class=\"number\">No.{number}</span> como aparece na imagem",
    "tips_2": "Por favor arrume os cabos e insira a power bank para baixo  <span class=\"highlight\">[arrow downward]</span> quando devolver.",
    "msg_note_usage": "A power bank está equipped com 3 tipos de cabos e carregamento wireless, por favor use quando precisar.",
    "msg_note_return": "Por favor arrume os cabos e insira a power bank para baixo [arrow downward] quando devolver.",
    "return_info": "Quando devolver, organize os cabos e insira o lado com chips metálicos nas bases até que cheguem ao fundo e fiquem estabilizadas."
  },
  "result_msg": {
    "loading": "Loading...",
    "refresh": "Atualizar"
  },
  "order": {
    "title": "Pedido de aluguer",
    "in_use": "Em uso",
    "ended": "Terminado",
    "msg_error": "Pedido falhou",
    "enter_vat_code": "Por favor insira o código do contribuinte",
    "enter_order_email": "Por favor insira o e-mail",
    "enter_recipient_name": "Insira o nome do destinatário"
  },
  "order_item": {
    "order_number": "No.",
    "venue_rented": "Local do aluguer",
    "time_rented": "Tempo alugado",
    "venue_returned": "Local da devolução",
    "time_returned": "Tempo devolvido",
    "charge_standard": "Cobrança standard",
    "charge_standard_info": "{pJifei} {pCurrency} por hora(first {pMian} mins for free)",
    "rent_occurred": "Aluguer realizado",
    "returned": "Devolvido",
    "unreturned": "Por devolver",
    "type_0": "Não Completo",
    "type_1": "A alugar",
    "type_2": "Cancelado",
    "type_3": "Devolvido",
    "type_4": "Falha ocorrida",
    "type_5": "Tempo excedido",
    "min": "Min",
    "payment": "Pagamento",
    "invoice_receipt": "Fatura"
  },
  "setting": {
    "title": "Configurações",
    "lang": "Idioma"
  },
  "help": {
    "title": "FAQ"
  },
  "wallet_in": {
    "title": "Recarga do Saldo",
    "price_label": "Valor de recarga",
    "price_placeholder": "Por favor insira o valor de recarga",
    "balance": "Saldo Atual",
    "price_auto": "Escolha o valor a pagar",
    "price_tips": "Se solicitar, o seu saldo pode ser retirado. Pode demorar por volta de 1-5 dias úteis para aparecer na sua conta de pagamento.",
    "submit": "Submeter",
    "loading": "Loading...",
    "paying": "Saltar para a página de pagamento...",
    "msg_title": "Dicas",
    "msg_fail_order": "Criar pedido de recarga falhou",
    "msg_fail_payment": "Falha de iniciar pagamento de recarga",
    "msg_fail_retry": "Voltar a tentar"
  },
  "wallet_out": {
    "title": "Retirar",
    "price_label": "Valor a retirar",
    "price_placeholder": "Por favor insira o valor a retirar",
    "balance": "Saldo atual",
    "balance_all": "Retirar na Totalidade",
    "deposit": "Depositar",
    "deposit_tips": "Existe um pedido de aluguer. O depósito de Ksh{pyajin} é não reembolsável.",
    "submit": "Submeter",
    "apply": "Aplicar",
    "confirm": "Confirmar",
    "loading": "Loading...",
    "msg_fail_price": "O valor a retirar está incorreto",
    "msg_fail_balance": "O valor a retirar não pode ser superior ao saldo atual",
    "msg_fail_submit": "Por favor preencha com informações completas",
    "msg_title": "Erro",
    "msg_success_content": "Aplicar Reembolso bem sucedido",
    "confirmtitle": "Dicas",
    "confirmcontent": "A carteira atual não suporta o levantamento do dinheiro. Por favor anote a sua informação de contacto e reembolso manualmente.",
    "payee_name": "Nome do Beneficiário",
    "payee_bankcard": "Número do cartão de débito",
    "payee_phone": "Telemóvel",
    "cancelText": "cancelar",
    "confirmText": "Reaplicar"
  },
  "map": {
    "title": "Perto do equipamento",
    "result_msg": "Nenhum equipamento detetado por perto",
    "msg_title": "erro",
    "no_support": "desculpe! O seu browser não suporta localização",
    "my_position": "a minha localização",
    "distance": "distância:",
    "shop_time": "horas",
    "free": "alugável",
    "return": "devolvível",
    "shop_title": "Detalhes do comerciante",
    "address": "Morada",
    "go_here": "Chegar aqui",
    "在线": "on line",
    "离线": "offline"
  }
}
