<template>
  <page :title="i18n.title">
    <div>
      <div class="walletout-balance">
        <p class="top"><span>{{i18n.balance}}</span><span>{{walletList[0] ? walletList[0].pCurrencySymbol : 'IDR'}} {{walletList[0] ? walletList[0].pBalance : user.pbalance || 0}}</span></p>
        <p class="bottom">{{$t('mine.deposit')}}:{{walletList[0] ? walletList[0].pDepositAmount : user.pyajin || 0}}</p>
      </div>
      <div class="walletout-form">
        <div class="walletout-label">{{i18n.price_label}}</div>
        <div class="walletout-input">
          <input type="number" v-model="price" :placeholder="i18n.price_placeholder" />
        </div>
      </div>
      <p class="allwithdrawal" @click="onAll()">{{ i18n.balance_all }}</p>
      <div class="walletout-btn">
        <button class="btn" @click="onSubmit">{{i18n.apply}}</button>
      </div>
    </div>
    <van-action-sheet v-model="show" :title="i18n.remark" @close="onClose">
      <van-cell is-link required :title="i18n.bank" :value="bankData.name" @click="selectshow = true" />
      <van-action-sheet v-model="selectshow" :actions="payoutChannels" @select="onSelect" />
      <template v-if="payoutConfig && payoutConfig.length > 0">
        <div v-for="(item, index) in payoutConfig" :key="index">
          <template v-if="item.fieldName === 'toBankCode' ? noBankCode : true">
            <template v-if="item.fieldType === 'picker'">
              <van-cell is-link required :title="$t(`column.${item.fieldName}`)" :value="apiData[item.fieldName].label" @click="payoutConfigSel(item.fieldDefauldValues, item.fieldName)" />
            </template>
            <template v-else-if="item.fieldType === 'input'">
              <van-field required input-align="right" v-model="apiData[item.fieldName].value" :label="item.fieldName" :placeholder="item.fieldName" />
            </template>
          </template>
        </div>
        <van-action-sheet v-model="configshow" :actions="pConfig" @select="onConfigSelect" />
      </template>
      <div class="walletout-popup-btn">
        <button class="btn" @click="onSubmit">{{i18n.apply}}</button>
      </div>
    </van-action-sheet>
  </page>
</template>

<script>
import { ActionSheet, Cell, Field } from 'vant'
import { REFUND_APPLY } from '../../apis/user.js'
import { WALLET_LIST } from '../../apis/payment.js'
import { mapState } from 'vuex'
export default {
  components: {
    VanActionSheet: ActionSheet,
    VanCell: Cell,
    VanField: Field
  },
  computed: {
    ...mapState(['user', 'browser', 'agentOpenId']),
    i18n () {
      return this.$t('wallet_out')
    },
    i18nMsg () {
      return this.$t('msg')
    },
    deposiTips () {
      return `${this.i18n.deposit_tips}`.format(this.user)
    }
  },
  data () {
    return {
      key: '',
      price: 0,
      bankData: {
        name: '',
        value: ''
      },
      noBankCode: false,
      apiData: {},
      walletList: [],
      payoutChannels: [], // 支付通道
      payoutConfig: [], // 支付通道下的配置项
      pConfig: [], // 支付通道下的配置项的每项数据
      show: false,
      selectshow: false,
      configshow: false,
      remark: '',
      checkNeedRemark: true,
      refundTypes: []
    }
  },
  methods: {
    onBack () {
      this.$router.go(-1)
    },
    onClose () {
      this.show = false
      this.checkNeedRemark = true
    },
    onAll () {
      if (this.walletList[0]) {
        this.price = this.walletList[0].pBalance
      } else if (this.user && this.user.pbalance) {
        this.price = this.user.pbalance
      }
    },
    onSelect (action, index) {
      console.log('onSelect===', action, index)
      this.bankData.name = action.name
      this.bankData.value = action.value
      this.payoutConfig = action.payoutConfig
      this.selectshow = false
    },
    payoutConfigSel (arr, key) {
      console.log('payoutConfigSel===', arr)
      this.key = key
      let array = []
      arr.forEach(v => {
        let obj = {
          name: v.desc,
          value: v.value
        }
        array.push(obj)
      })
      let list = []
      if (arr.length > 0 && this.apiData['transType'] && key === 'proxyType') {
        let pvalue = this.apiData['transType'].value
        list = arr.filter(v => {
          if (v.desc.indexOf(pvalue) !== -1) {
            v.name = v.desc
            return v
          }
        })
      }
      this.pConfig = key === 'proxyType' ? list : array
      this.configshow = true
    },
    onConfigSelect (action, index) {
      console.log('onConfigSelect====', action, index, this.key)
      this.apiData[this.key].label = action.name
      this.apiData[this.key].value = action.value
      if (this.key === 'transType') {
        this.apiData['proxyType'] = {
          label: '',
          value: ''
        }
      }
      if (this.key === 'proxyType') {
        if (action.value === '10') {
          this.apiData['toBankCode'] = {
            label: '',
            value: ''
          }
          this.noBankCode = true
        } else {
          delete this.apiData.toBankCode
          this.noBankCode = false
        }
      }
      this.configshow = false
    },
    getWalletist () {
      this.$loading(true)
      this.$get(
        WALLET_LIST,
        {
          agentId: this.agentId
        },
        resp => {
          this.$loading(false)
          console.log('Walletist', resp)
          this.walletList = resp.data
        },
        error => {
          this.$loading(false)
          this.$confirm({
            title: this.i18n.msg_fail_title,
            content: this.i18nMsg.error,
            confirmText: this.i18nMsg.retry,
            cancelText: this.i18nMsg.cancel
          }).then(() => {
            this.getWalletist()
          })
          console.log(error)
        }
      )
    },
    onSubmit () {
      let balance = this.walletList[0] ? this.walletList[0].pBalance : 0
      let that = this
      if (isNaN(this.price) || this.price <= 0) {
        this.$toast(this.i18n.msg_fail_price)
      } else if (this.price > balance) {
        this.$toast(this.i18n.msg_fail_balance)
      } else {
        let url = REFUND_APPLY
        let param = {
          agentId: this.walletList[0].pDailiId,
          amount: this.price,
          amountType: 'balance',
          checkNeedRemark: this.checkNeedRemark,
          remark: '1'
        }
        let info = {}
        if (!this.checkNeedRemark && this.show) {
          for (const key in that.apiData) {
            let d = that.apiData[key]
            console.log('d=====', d)
            if (d.value) {
              info[key] = d.value
            } else {
              that.$toast(that.i18n.msg_fail_tixianinfo)
              throw new Error('所有信息必填')
            }
          }
          param.autoAudit = true
          param.refundTyp = 2
          param.receiverAccount = {
            paymentId: that.bankData.value,
            accountData: {
              ...info
            }
          }
        } else if (this.refundTypes && this.refundTypes.includes(0)) {
          param.autoAudit = true
        }
        console.log('参数======', param)
        this.$loading(true)
        this.$post(
          url,
          {
            ...param
          },
          resp => {
            this.$loading(false)
            let that = this
            this.refundTypes = resp.data.refundTypes
            if (this.refundTypes && this.refundTypes.includes(0) && this.checkNeedRemark) {
              this.checkNeedRemark = !this.checkNeedRemark
              this.onSubmit()
              return
            }
            if (resp.data && this.checkNeedRemark) {
              this.show = true
              if (resp.data.payoutChannels && resp.data.payoutChannels.length > 0) {
                let arr = []
                resp.data.payoutChannels.forEach(v => {
                  let obj = {
                    name: v.channelName,
                    value: v.paymentId,
                    payoutConfig: v.payoutConfig
                  }
                  arr.push(obj)
                  if (v.payoutConfig && v.payoutConfig.length > 0) {
                    v.payoutConfig.forEach(v => {
                      that.apiData[v.fieldName] = {
                        label: '',
                        value: ''
                      }
                    })
                  }
                })
                this.payoutChannels = arr
                console.log('apidata===', this.apiData)
              }
            } else if (resp.data && !resp.data.tixianOrderId) {
              this.onSubmit()
            } else {
              this.price = 0
              this.$toast(this.i18n.msg_success_content)
              this.getWalletist()
              this.show = false
            }
            console.log(resp)
            this.checkNeedRemark = !this.checkNeedRemark
          },
          error => {
            this.$loading(false)
            let errstr = ''
            if (error.includes('余额不足')) {
              errstr = this.i18nMsg.msg_fail_balance
            } else {
              errstr = error
            }
            this.$confirm({
              title: this.i18n.msg_title,
              content: errstr,
              showCancel: false,
              confirmText: this.i18n.confirm
            })
          },
          {
            headers: {
              'Content-Type': 'application/json;charset=UTF-8'
            }
          }
        )
      }
    }
  },
  created () {
    this.getWalletist()
  }
}
</script>

<style lang="less">
.walletout-form{
  // background: #FFFFFF;
  padding: 40px 40px 0;
  .walletout-label{
    font-size: 28px;
    font-weight: 500;
    margin-bottom: 20px;
  }
  .walletout-input{
    padding: 20px 0;
    display: flex;
    align-items: center;
    font-size: 56px;
    font-weight: 500;
    color: #333333;
    input{
      width: 100%;
      height: 68px;
      line-height: 68px;
      padding: 10px 20px;
      color: #333333;
      font-size: 30px;
      border: none;
      outline: none;
      border-radius: 20px;
    }
  }
}
.walletout-balance{
  background: #3FDB41;
  border-radius: 30px;
  width: 80%;
  margin: 60px auto 0;
  font-size: 32px;
  font-weight: 400;
  color: #fff;
  padding: 10px 40px;
  .top{
    margin: 20px 0 20px;
    font-size: 36px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .bottom{
    margin: 0;
    font-size: 24px;
    text-align: end;
  }
}
.wallet-deposit{
  background: #FFFFFF;
  margin-top: 20px;
  padding: 26px 40px;
}
.allwithdrawal{
  text-align: center;
  color: #3FDB41;
  font-size: 28px;
  font-weight: 500;
}
.walletout-btn{
  width: 85%;
  margin: 0 auto;
  position: absolute;
  bottom: 15%;
  left: 50%;
  transform: translateX(-50%);
  button {
    width: 100%;
    border: none;
    padding: 0;
    margin: 0;
  }
}
.walletout-popup-textarea{
  display: block;
  width: 85%;
  margin: 0 auto;
  border-radius: 20px;
  outline: none;
  padding: 10px;
}
.walletout-popup-btn{
  width: 85%;
  margin: 40px auto;
  button {
    width: 100%;
    border: none;
    padding: 0;
    margin: 0;
  }
}
</style>
