export const STRIPE_PRE_PAY = 'cdb/payment/stripe/prepay' // 原先的预授权租借
export const STRIPE_RECHARGE = 'cdb/payment/stripe/recharge' // 原先的订单付款
export const STRIPE_PREPAY_SPTOKEN = 'cdb/payment/stripe/multicurrency/preAuth/stripToken' // 目前使用的预授权租借
export const STRIPE_PAYORDER_SPTOKEN = 'cdb/payment/stripe/multicurrency/payOrder/stripToken' // 目前使用的订单付款
export const STRIPE_PAYORDER = 'cdb/payment/stripe/multicurrency/payOrder' // 信用卡订单付款
export const STRIPE_RECHARGE_WALLET = 'cdb/payment/stripe/multicurrency/rechargeWallet' // 创建钱包充值订单

export const STRIPE_PREAUTH_OF_CONFIRM = 'cdb/payment/stripe/multicurrency/preauthOfIntent/payment' // 信用卡预授权租借
export const STRIPE_CLIENTSECRET = 'cdb/payment/stripe/multicurrency/clientSecret' // 获取客户秘钥
export const STRIPE_PREAUTH_AUTO_CONFIRM1 = 'cdb/payment/stripe/authConfirm'
export const STRIPE_PREAUTH_AUTO_CONFIRM = 'cdb/payment/stripe/multicurrency/confirm' // 信用卡确认订单
export const STRIPE_PAYCONFIRM = 'cdb/payment/stripe/multicurrency/payConfirm/payment' // 确认订单-旧

export const PAYPAL_PRE_PAY = 'cdb/payment/paypal/prepay'
export const PAYPAL_RECHARGE = 'cdb/payment/paypal/recharge'
export const PAYPAL_EXC_PAY = 'cdb/payment/paypal/excpay'
export const PAYPAL_PREAUTH = "cdb/payment/paypal/multicurrency/preauth"; // paypal创建预授权订单
export const PAYPAL_CONFIRM =
  "cdb/payment/paypal/multicurrency/authConfirm"; // paypal确认订单
export const WALLET_LIST = 'cdb/user/wallet' // 钱包列表
export const PAYMODE = 'cdb/cabinet/check/payment' // 获取支付方式列表
// midtrans
export const MIDTRANS_RECHARGE = 'cdb/payment/midtrans/multicurrency/recharge' // 押金充值
export const MIDTRANS_PAYORDER = 'cdb/payment/midtrans/multicurrency/payOrder' // 订单付款
export const MIDTRANS_VIPORDER = 'cdb/payment/midtrans/multicurrency/vipOrder' // 购买vip
export const MIDTRANS_PAYCONFIRM = 'cdb/payment/midtrans/multicurrency/payment/confirm' // 确认订单

// easyPay 支付
export const EASYPAY_PREAUTHORDER = 'cdb/payment/easyPay/preAuthOrder' // 创建预授权订单
export const EASYPAY_PAYCONFIRM = 'cdb/payment/easyPay/payOrder' // 订单付款
export const EASYPAY_CONFIRM = 'cdb/payment/easyPay/payment/confirm' // 确认订单是否授权或支付
export const EASYPAY_SAVEPAYMENTMETHORD = 'cdb/payment/easyPay/savePaymentMethord' // 保存支付方式
export const EASYPAY_SAVEPAYMENTMETHORD_CONFIRM = 'cdb/payment/easyPay/savePaymentMethord/confirm' // 保存支付方式确认
