<template>
  <div>
    <van-dialog
      v-model="dialogModel"
      show-cancel-button
      :confirmButtonText="$t('confirm.msg_fail_confirm')"
      :cancelButtonText="$t('confirm.msg_fail_cancel')"
      :before-close="onEasyPay"
    >
      <div class="easyPay-popup">
        <div class="easyPay-popup-number">
          <div class="area" @click="isAreaView = true">
            <span>+{{ easyPayInfo.areaCode }}</span>
            <div class="area-down"></div>
          </div>
          <van-field
            v-model="easyPayInfo.phone"
            :placeholder="$t('login.phone_number')"
          />
        </div>
        <van-field
          class="easyPay-popup-email"
          v-model="easyPayInfo.email"
          :placeholder="$t('order.enter_order_email')"
        />
      </div>
    </van-dialog>
    <select-area v-model="isAreaView" @select="onSelct"></select-area>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import { SelectArea } from "../select"
export default {
  props: {
    dialogPopup: {
      type: Boolean,
      default: false
    }
  },
  components: {
    SelectArea
  },
  watch: {
    dialogPopup(val) {
      this.dialogModel = val
    }
  },
  computed: {},
  data() {
    return {
      dialogModel: false,
      isAreaView: false,
      easyPayInfo: {
        phone: "", // "911234567",
        email: "",
        areaCode: "351"
      }
    }
  },
  methods: {
    onSelct(val) {
      this.easyPayInfo.areaCode = val
    },
    // 确认支付
    onEasyPay(action, done) {
      if (action === "confirm") {
        // this.$emit("onConfirm", this.easyPayInfo)
        if (!this.easyPayInfo.phone) {
          this.$toast(this.$t("login.phone_number"))
          return done(false)
        } else if (!this.easyPayInfo.email) {
          this.$toast(this.$t("order.enter_order_email"))
          return done(false)
        }
        done()
        this.$emit("onEasyPay", { show: false, ...this.easyPayInfo })
      } else if (action === "cancel") {
        this.$emit("onEasyPay", { show: false })
        done()
      }
    }
  },
  created() {}
}
</script>

<style lang="less" scoped>
.easyPay-popup {
  margin: 64px 0 32px;
  padding: 24px 46px;
  &-number {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    padding-left: 24px;
    border-radius: 16px;
    border: 1px solid #ccc;
    overflow: hidden;
  }
  &-email {
    border-radius: 16px;
    border: 1px solid #ccc;
  }
}
</style>
