<template>
  <page>
    <div slot="head" class="page-head-default">
      <img src="../../assets/imgs/back.png" alt="" @click="onBack" />
      {{ i18n.code_title }}
    </div>
    <div class="login-code-warp">
      <div class="top-tips">{{ i18n.verification_code }}</div>
      <img
        class="imgverifcode"
        src="../../assets/imgs/img_verifcode.png"
        alt=""
      />
      <div class="code-input-main" @click="onFocus">
        <div
          class="code-input-main-item"
          :class="{ bordermaincolor: isFocus && code.length === 0 }"
        >
          {{ code[0] }}
          <div class="item-cursor" v-if="isFocus && code.length === 0"></div>
        </div>
        <div
          class="code-input-main-item"
          :class="{ bordermaincolor: isFocus && code.length === 1 }"
        >
          {{ code[1] }}
          <div class="item-cursor" v-if="isFocus && code.length === 1"></div>
        </div>
        <div
          class="code-input-main-item"
          :class="{ bordermaincolor: isFocus && code.length === 2 }"
        >
          {{ code[2] }}
          <div class="item-cursor" v-if="isFocus && code.length === 2"></div>
        </div>
        <div
          class="code-input-main-item"
          :class="{ bordermaincolor: isFocus && code.length === 3 }"
        >
          {{ code[3] }}
          <div
            class="item-cursor"
            v-if="isFocus && code.length >= 3"
            :class="{ end: code.length === 4 }"
          ></div>
        </div>
        <input
          id="codeinput"
          class="code-input-input"
          v-model="code"
          maxlength="4"
          type="number"
          @blur="onBlur"
        />
      </div>
      <div class="login-code-btn">
        <div class="btn" @click="onSubmit">{{ i18n.login }}</div>
      </div>
      <div class="remark">
        {{ i18n.no_sms }}<br />
        <span class="again" @click="onAgain">{{ i18n.retry }}</span>
      </div>
    </div>
  </page>
</template>

<script>
export default {
  computed: {
    i18n() {
      return this.$t("login")
    }
    // loginDisabled () {
    //   return this.code.length !== 4
    // }
  },
  watch: {
    isFocus(val, oldVal) {
      if (!val) {
        this.inputStatus = false
      }
    },
    code(val, oldVal) {
      if (val.length > 4) {
        this.code = val.slice(0, 4)
      }
    }
  },
  data() {
    return {
      code: "",
      isFocus: false,
      inputStatus: false
    }
  },
  methods: {
    onFocus() {
      let node = document.getElementById("codeinput")
      node.focus()
      this.isFocus = true
    },
    onBlur() {
      this.isFocus = false
    },
    onSubmit() {
      this.$emit("submit", this.code)
    },
    onAgain() {
      this.$emit("again")
    },
    onBack() {
      this.$emit("back")
    }
  }
}
</script>

<style lang="less">
@keyframes blink {
  0% {
    background-color: #44d62c;
  }
  50% {
    background-color: #fff;
  }
  100% {
    background-color: #44d62c;
  }
}
.login-code-warp {
  color: #44d62c;
  font-size: 28px;
  background-color: #001729;
  padding: 0 55px;
  position: fixed;
  top: 80px;
  bottom: 0;
  left: 0;
  right: 0;
  .top-tips {
    padding: 60px 0 60px;
    text-align: center;
    line-height: 40px;
  }
  .imgverifcode {
    width: 50%;
    display: block;
    margin: 0 auto 40px;
  }
  .code-input-input {
    height: 1px;
    width: 1px;
    position: absolute;
    left: -200px;
    bottom: 0;
    font-size: 10px;
    caret-color: transparent;
    color: transparent;
    z-index: -999;
  }
  .code-input-main {
    width: 85%;
    height: 100px;
    display: flex;
    justify-content: space-between;
    position: relative;
    margin: 0 auto;
  }
  .code-input-main-item {
    width: 50px;
    flex: 1;
    margin: 0 20px;
    border: 2px solid #c6c6c6;
    text-align: center;
    font-size: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: #fff;
    border-radius: 15px;
    .item-cursor {
      width: 1px;
      height: 60px;
      background-color: #fff;
      animation: blink 1s infinite steps(1, start);
    }
    .end {
      position: absolute;
      right: 0;
    }
  }
  .bordermaincolor {
    border-color: #10d269;
  }
  .login-code-btn {
    padding-top: 60px;
    .btn {
      margin: 24px;
    }
  }
  .remark {
    padding-top: 20px;
    line-height: 40px;
    text-align: center;
    .again {
      display: inline-block;
      font-weight: 500;
      border-bottom: 1px solid #44d62c;
    }
  }
}
</style>
