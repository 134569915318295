<template>
  <div id="app">
    <router-view />
    <popup-loading v-if="loading"></popup-loading>
  </div>
</template>

<script>
import { mapState } from "vuex"
import { PopupLoading } from "./components/popup"
import { vConsoleWidget } from "@/utils/index"
export default {
  components: {
    PopupLoading
  },
  computed: {
    ...mapState(["loading"])
  },
  created() {
    const { vc } = this.$route.query
    if (vc === "true") vConsoleWidget()
  }
}
</script>

<style lang="less">
@import "./assets/css/public.less";
body {
  background: #001729;
  padding: 0;
  margin: 0;
  height: 100vh;
  color: #44d62c;
}
#app {
  font-size: 28px;
}
.btn {
  height: 86px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #001729;
  background: #44d62c;
  font-size: 32px;
  font-weight: bold;
  // border-radius: 20px;
}
.text-primary {
  color: #3fdb41;
}
.text-bold {
  font-weight: bold;
}
</style>
